@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Teko&display=swap");
@import url("https://fonts.googleapis.com/css?family=Rambla&display=swap");

body #fonts {
  font-family: "Montserrat", sans-serif;
  /* font-weight: 400; */
  /* text-transform: uppercase; */
  /* letter-spacing: 0.1em; */
}
body {
  font-family: "Rambla", sans-serif;
  /* font-weight: 400; */
  /* text-transform: uppercase; */
  /* letter-spacing: 0.1em; */
  background-color: #292929;
  /* overflow: hidden; */
}
.ui.cards > .red.card,
.ui.red.card {
  box-shadow: none;
}

.ui.card,
.ui.cards > .card {
  border-radius: 1rem !important ;
}

.ui.card.cardMain > :first-child,
.ui.cards.cardMain > .card > :first-child {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.ui.inverted.top.fixed.navbar.menu {
  background-image: linear-gradient(135deg, #1f2024 0%, #28292b 89%);
  border-bottom: 2px solid #ffa600;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #cf8e5e !important;
}
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #cf8e5e !important;
}

.ui.inverted.orange.button {
  color: #fbfdff;
}
.ui.inverted.orange.button:hover {
  background-color: #ffa600;
}
.ui.inverted.accordion .title:not(.ui) {
  color: #cf8e5e;
}
.ui.inverted.menu .item:hover:not(div) {
  border-bottom: 1px solid #ffa600;
}
.ui.basic.inverted.button.dropButton {
  box-shadow: none !important;
  background: none !important;
}
.ui.basic.button.dropButton {
  box-shadow: none !important;
  background: none !important;
}

.ui.basic.inverted.button.dropButton:hover {
  box-shadow: none !important;
  background: none !important;
  color: #e2e2e2 !important;
}

.ui.basic.button.dropButton:hover {
  box-shadow: none !important;
  background: none !important;
  color: #000000 !important;
}

.ui.mini.steps {
  background: linear-gradient(135deg, #f72585 0%, #7209b7 69%, #3a0ca3 89%);
}

.react-datepicker-wrapper {
  width: 100%;
}
/* .pushable:not(body).patch {
  -webkit-transform: unset;
  transform: unset;
}
.pushable:not(body) .patch.ui.sidebar {
  position: fixed;
} */
.ui.basic.segment.patch {
  background-color: #eaeaea;
}

.indent {
  margin-left: 1em;
}

.no-padding {
  padding: 0px !important;
  margin: 0px !important;
}

.ui.accordion {
  margin-top: 70px;
}
/* .sidebarMenuTest {
  margin-top: 70px;
} */

.ui.accordion .title:not(.ui) {
  padding: 0.2em;
}
.ui.labeled.icon.menu {
  text-align: left;
}

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    #292929 0%,
    #1f2024 69%,
    #28292b 89%
  ) !important ;
  height: 100vh;
}

.masthead h1.ui.header {
  font-size: 4em;
}

.react-calendar {
  width: 100%;
  border: none;
}

.react-calendar__tile--now {
  background: rgb(85, 187, 187);
}

/* .sidebar.visible + .pusher {
  width: calc(100% - 150px);
} */

.img-map-static {
  width: 100%;
}

.ui.comments .comment .avatar img,
.ui.comments .comment img.avatar {
  border-radius: 50%;
}

.ui.modal > .content {
  background-color: #fafafa;
}

.ui.comments .comment > .content {
  background-color: #f3f3f3;
  border-radius: 0.4rem;
  padding: 0.5rem;
}

.ui.card.horizontal > .image:last-child > img {
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.ui.card.vertical > .img-ratio {
  position: relative;
  display: block;
}
.ui.card.vertical > .img-ratio::before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 56.25%;
}

.ui.card.vertical > .img-ratio > .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}

.ui.cards > .card > .ui.placeholder {
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}

/* below styling is for custom horizantal card, however now we can use fomatic UI instead */
.ui.image.card-main > img {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  /* display: inline-block; */
  height: 100%;
  object-fit: cover;
}

/* .ui.basic.segment.card-main {
  background-color: #f72585;

  bottom: 0;

  position: absolute;
  width: 100%;
} */

.ui.card > .content.card-main {
  /* background-color: cornsilk; */
  display: flex;
  justify-content: space-between;
  padding: 0rem;
  border-radius: 1rem;
}
.ui.card > .content > .card-cont {
  /* background-color: #f72585; */
  padding: 1rem;
  flex: 1 1 70%;
}
.ui.card > .content > .card-hero {
  /* background-color: #ffa600; */

  position: relative;
  flex: 1 1 30%;
}
.ui.card > .content > .card-cont > .content.card-main-content {
  background-color: brown;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0.2rem;
  height: 100%;
}

.ui.card > .content > .card-cont > .content > .card-extra {
  background-color: #ffa600;
  display: flex;
  justify-content: space-between;
}
.ui.card > .content > .card-cont > .content > .card-inner-content {
  background-color: aqua;
  flex: 1;
}

.ui.card > .content > .card-hero > .card-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.ui.multiple.dropdown > .label {
  display: inline-flex;
  /* font-size: small; */
  font-weight: 100;
  box-shadow: none;
}
.ui.multiple.dropdown {
  /* min-height: auto !important; */
}
.ui.multiple.dropdown > .label > img {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  border-radius: 0.2rem;
  aspect-ratio: 1/1;
}
/* making top second header sticky */
.ui.table.rds thead tr:nth-child(2) > th {
  position: sticky !important;
  top: 0;
  z-index: 4;
  background-color: rgba(50, 50, 50, 1) !important;
}

/* making right side title sticky */
.ui.table.rds tbody tr > td:first-child {
  position: sticky !important;
  left: 0;
  z-index: 3;
  background-color: rgba(50, 50, 50, 1) !important;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: rgba(73, 73, 73, 0.5) 10px 0px 10px 0px;
}
.ui.table.rds thead tr:nth-child(2) > th:first-child {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 5;
  background-color: rgba(50, 50, 50, 1) !important;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: rgba(73, 73, 73, 0.5) 10px 0px 10px 0px;
}
/* making left side tools sticky */
.ui.table.rds tbody tr > td:last-child {
  position: sticky !important;
  right: 0;
  /* z-index: 3; */
  background-color: rgba(50, 50, 50, 1) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: rgba(73, 73, 73, 0.5) -10px 0px 10px 0px;
}
.ui.table.rds thead tr:nth-child(2) > th:last-child {
  position: sticky !important;
  right: 0;
  top: 0;
  z-index: 5;
  background-color: rgba(50, 50, 50, 1) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: rgba(73, 73, 73, 0.5) -10px 0px 10px 0px;
}
